<template>
  <div class="pay">
    <!-- <object :data="url" width="100%" height="100%" /> -->
    <iframe :src="url" scrolling="no" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
import { isSafari } from '../../utils/tools';
export default {
  data() {
    return {
      url: '',
    };
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.cb);
  },
  methods: {
    cb() {
      if (document.hidden) {
        // 页面被挂起
        console.log('页面被挂起');
      } else {
        console.log('页面呼出');
        // 页面呼出 支付宝购买后返回，刷新次数接口
        const query = {
          token: localStorage.getItem('token'),
          order_no: this.$route.query.orderNo,
        };
        this.$api.loop(query).then((e) => {
          if (e.code != 100000) return this.$toast(e.msg);
          this.info = JSON.stringify(e.data);
          if (e.data.status === 2) {
            if (this.$route.query.type == 1) {
              this.$router.go(-2);
            } else {
              const { orderType, id } = this.$route.query;
              if (orderType == 2) {
                this.$router.replace(
                  `unpaid?id=${id}&orderType=${orderType}&isShowTimeOut=1`,
                );
              } else {
                this.$router.replace(`unpaid?id=${id}&orderType=${orderType}`);
              }
              // this.$router.replace('/Allorder?type=2');
            }
            return this.$toast('支付成功');
          } else {
            if (
              this.$route.query.orderType &&
              this.$route.query.orderType != 3
            ) {
              const { orderType, id } = this.$route.query;
              this.$router.replace(`unpaid?id=${id}&orderType=${orderType}`);
            } else {
              this.$router.back();
            }
            return this.$toast('支付失败');
          }
        });
      }
    },
  },
  mounted() {
    document.addEventListener('visibilitychange', this.cb);
    if (isSafari()) {
      window.location.href = this.$route.query.payUrl;
    } else {
      this.url = this.$route.query.payUrl;
    }
  },
};
</script>

<style lang="less" scoped>
.pay {
  width: 100%;
  height: 100vh;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
